<script>

import axios from 'axios';
import { definitions } from '@sword-health/ui-core';
import moment from 'moment';

export default {
  name: 'TabLicenses',
  components: {},
  props: {
    currentTab: { type: Number, required: true },
    tabIndex: { type: Number, required: true },
  },
  data: () => ({
    loaded: false,
    loading: true,
    user_id: 0,
    license_options: null,
    licenses: null,
    temp_license: {
      id: null,
      geographical_state: null,
      regulatory_status: false,
      license_id: null,
      expiration_date: null,
    },
    tableFields: [
      { key: 'geographical_state', label: 'State' },
      { key: 'regulatory_status', label: 'Regulatory status' },
      { key: 'license_id', label: 'License ID' },
      { key: 'expiration_date', label: 'Expiration Date' },
      { key: 'actions', label: 'Actions' },
    ],
  }),
  watch: {
    currentTab(value) {
      if (this.tabIndex === value && this.loaded === false) {
        this.fetchData();
      }
    },
  },
  beforeMount() {
    this.user_id = this.$route.params.id;
  },
  computed: {
    states() {
      const states = definitions.getStatesByCountry(this.$store.getters['Core/getSelectedEnv']) || definitions.USAStatesOpts;
      return [
        {
          value: null,
          text: 'Choose a state',
        },
        ...states.map(state => ({
          value: state.optValue,
          text: `${state.optValue} - ${state.optLabel}`,
        })),
      ];
    },
  },
  methods: {
    fetchData() {
      this.loaded = true;
      this.loading = true;
      return axios.get(`/v1/therapists/${this.user_id}/licenses?client=back-office`)
        .then(response => {
          this.licenses = response.data.licenses.map(license => ({
            ...license,
            expiration_date: license.expiration_date ? new Date(license.expiration_date) : null,
          }));
        })
        .catch(err => {
          this.$noty.error('Could not licenses groups');
          console.error('Could not fetch licenses', err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    formatDatetime(value) {
      if (!value) {
        return null;
      }
      return moment(value).utc().format('MMM DD, YYYY');
    },
    completeStateName(stateCode) {
      const state = this.states.find(s => s.value === stateCode);
      return state ? state.text : stateCode;
    },

    deleteLicenseConfirmation(licenceData) {
      return this.$bvModal.msgBoxConfirm(`Delete licence for ${licenceData.geographical_state}?`)
        .then(result => {
          if (result) {
            this.deleteLicense(licenceData);
          }
        });
    },
    deleteLicense(licenceData) {
      return axios.delete(`/v1/therapists/${this.user_id}/licenses/${licenceData.id}`)
        .then(() => {
          this.$noty.success('License deleted');
          this.fetchData();
        })
        .catch(err => {
          this.$noty.error('Could not delete license');
          console.error('Could not delete license', err);
        });
    },

    editModalOpen(licenceData) {
      if (licenceData) {
        this.temp_license = {
          id: licenceData.id,
          geographical_state: licenceData.geographical_state,
          regulatory_status: licenceData.regulatory_status,
          license_id: licenceData.license_id,
          expiration_date: licenceData.expiration_date,
        };
      }

      this.$bvModal.show('modal-edit-license');
    },
    upsertLicense() {
      if (!this.temp_license.license_id || !this.temp_license.geographical_state || !this.temp_license.expiration_date) {
        return this.$noty.error('Please fill all fields');
      }
      const editing = !!this.temp_license.id;

      return axios.request({
        url: `/v1/therapists/${this.user_id}/licenses`,
        method: editing ? 'PUT' : 'POST',
        data: [ {
          id: this.temp_license.id,
          user_id: this.user_id,
          license_id: this.temp_license.license_id,
          geographical_state: this.temp_license.geographical_state,
          regulatory_status: this.temp_license.regulatory_status ? 1 : 0,
          expiration_date: this.temp_license.expiration_date,
        } ],
      })
        .then(() => {
          this.fetchData();
          this.$bvModal.hide('modal-edit-license');
          this.$noty.success(editing ? 'License edited' : 'License added');

          this.temp_license = {
            id: null,
            geographical_state: null,
            regulatory_status: false,
            license_id: null,
            expiration_date: null,
          };
        })
        .catch(err => {
          this.$noty.error('Could not edit license');
          console.error('Could not edit license', err);
        });
    },
  },
};
</script>

<template>
  <b-tab title="Licenses">
    <b-card-text>

      <b-row>
        <b-col cols="12">
          <b-button class="float-right" variant="primary" @click="editModalOpen">Add license</b-button>
        </b-col>
      </b-row>

      <b-row class="mt-3">
        <b-col cols="12">
          <b-table :fields="tableFields" :items="licenses" :busy="loading" outlined striped show-empty>
            <template #cell(expiration_date)="data">
              {{ formatDatetime(data.value) }}
            </template>
            <template #cell(geographical_state)="data">
              {{ completeStateName(data.value) }}
            </template>
            <template #cell(regulatory_status)="data">
              <feather v-if="data.value" class="text-success" type="check"></feather>
              <feather v-else class="text-danger" type="x"></feather>
            </template>
            <template #cell(actions)="data">
              <b-button title="Edit" size="sm" variant="light" class="mr-2" @click="editModalOpen(data.item)">
                <feather type="edit"></feather>
              </b-button>
              <b-button title="Delete" size="sm" variant="light" @click="deleteLicenseConfirmation(data.item)">
                <feather class="text-danger" type="trash"></feather>
              </b-button>
            </template>
          </b-table>
        </b-col>
      </b-row>
    </b-card-text>

    <b-modal id="modal-edit-license"
             :title="temp_license.id ? `Edit license ${ temp_license.geographical_state }` : 'Add License'">
      <b-container fluid>
        <b-form-group label="State:">
          <b-form-select v-model="temp_license.geographical_state"
                         :disabled="!!temp_license.id"
                         :options="states" />
        </b-form-group>
        <b-form-group label="License ID:">
          <b-form-input v-model.trim="temp_license.license_id" type="text" />
        </b-form-group>
        <b-form-group label="Expiration date:">
          <b-form-datepicker v-model="temp_license.expiration_date" class="mr-3"
                             :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit'}"
                             :min="new Date()" placeholder="Expiration date" />
        </b-form-group>
        <b-form-group>
          <b-form-checkbox v-model="temp_license.regulatory_status" class="mr-3" switch
                           :value="1" :unchecked-value="0">Regulatory status
          </b-form-checkbox>
        </b-form-group>
      </b-container>
      <template #modal-footer>
        <b-button class="float-right" variant="primary" v-if="temp_license.id"
                  @click="upsertLicense">Edit license</b-button>
        <b-button class="float-right" variant="primary" v-else
                  @click="upsertLicense">Add license</b-button>
      </template>
    </b-modal>
  </b-tab>


</template>
