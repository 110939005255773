<script>

import axios from 'axios';

export default {
  name: 'TabCredentials',
  components: {},
  props: {
    currentTab: { type: Number, required: true },
    tabIndex: { type: Number, required: true },
  },
  data: () => ({
    loaded: false,
    loading: true,
    user_id: 0,
    credential_options: null,
    credential_groups: null,
    new_credential: null,
    tableFields: [
      { key: 'name', label: 'Credential Group' },
      { key: 'actions', label: 'Actions' },
    ],
  }),
  watch: {
    currentTab(value) {
      if (this.tabIndex === value && this.loaded === false) {
        this.fetchData();
      }
    },
  },
  beforeMount() {
    this.user_id = this.$route.params.id;
  },
  methods: {
    fetchData() {
      this.loaded = true;
      this.loading = true;
      return axios.get(`/v1/therapists/${this.user_id}/credential-groups`)
        .then(response => {
          this.credential_groups = response.data;
          this.fetchCredentialOptions();
        })
        .catch(err => {
          this.$noty.error('Could not credential groups');
          console.error('Could not fetch credential-groups', err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    fetchCredentialOptions() {
      return axios.get('/v1/credential-groups')
        .then(response => {
          this.credential_options = [ { value: null, text: 'Choose a credential' } ];
          response.data.forEach(credentialGroup => {
            this.credential_options.push({
              value: credentialGroup.id,
              text: credentialGroup.name,
              disabled: this.credential_groups.some(credential => credential.id === credentialGroup.id),
            });
          });
        })
        .catch(err => {
          this.$noty.error('Could not credential-group options');
          console.error('Could not fetch credential-group options', err);
        });
    },

    addCredentialConfirmation() {
      const selectedCredential = this.credential_options.find(credential => credential.value === this.new_credential);
      return this.$bvModal.msgBoxConfirm(`Add credential ${selectedCredential.text}?`)
        .then(result => {
          if (result) {
            this.addCredential();
          }
        });
    },
    addCredential() {
      return axios.post(`/v1/therapists/${this.user_id}/credential-groups`, {
        credentialGroupId: this.new_credential,
      })
        .then(() => {
          this.$noty.success('Credential added');
          this.new_credential = null;
          this.fetchData();
        })
        .catch(err => {
          this.$noty.error('Could not add credential');
          console.error('Could not add credential', err);
        });
    },

    deleteCredentialConfirmation(credentialData) {
      return this.$bvModal.msgBoxConfirm(`Delete credential ${credentialData.name}?`)
        .then(result => {
          if (result) {
            this.deleteCredential(credentialData);
          }
        });
    },
    deleteCredential(credentialData) {
      return axios.delete(`/v1/therapists/${this.user_id}/credential-groups/${credentialData.id}`)
        .then(() => {
          this.fetchData();
        })
        .catch(err => {
          this.$noty.error('Could not delete credential');
          console.error('Could not delete credential', err);
        });
    },
  },
};
</script>

<template>
  <b-tab title="Credentials">
    <b-card-text>
      <b-row>
        <b-col cols="12">
          <b-card>
            <b-form inline class="d-flex">
              <label class="mr-5">New credential</label>
              <div class="flex-fill">
                <b-form-select v-model="new_credential" :options="credential_options" class="mr-2" />
              </div>
              <b-button variant="primary" @click="addCredentialConfirmation">Add credential</b-button>
            </b-form>
          </b-card>
        </b-col>
      </b-row>
      <b-row class="mt-3">
        <b-col cols="12">
          <b-table :fields="tableFields" :items="credential_groups" :busy="loading" outlined striped show-empty>
            <template #cell(actions)="data">
              <b-button title="Delete" size="sm" variant="light" @click="deleteCredentialConfirmation(data.item)">
                <feather class="text-danger" type="trash"></feather>
              </b-button>
            </template>
          </b-table>
        </b-col>
      </b-row>
    </b-card-text>
  </b-tab>
</template>
