<script>

import {
  UNIT_BLOOM, UNIT_MOVE, UNIT_SWORD, UNIT_MIND,
} from '@/scripts/constants';

export default {
  name: 'TabUnits',
  components: {},
  props: {
    currentTab: { type: Number, required: true },
    tabIndex: { type: Number, required: true },
  },
  data: () => ({
    loaded: false,
    user_id: 0,
    user_units: [],
    unitOptions: [
      { value: UNIT_SWORD, html: 'Thrive' },
      { value: UNIT_BLOOM, text: 'Bloom' },
      { value: UNIT_MOVE, text: 'Move' },
      { value: UNIT_MIND, text: 'Mind' },
    ],
  }),
  watch: {
    currentTab(value) {
      if (this.tabIndex === value && this.loaded === false) {
        this.fetchData();
      }
    },
  },
  beforeMount() {
    this.user_id = this.$route.params.id;
  },
  methods: {
    fetchData() {
      this.loaded = true;
      console.log('loading units');
    },
  },
};
</script>

<template>
  <b-tab title="Units">
    <b-card-text>
      <b-row>
        <b-col cols="12">
          Not done yet
        </b-col>
      </b-row>
      <hr />
      <b-row>
        <b-col cols="12">
          <b-button variant="primary" class="float-right">Save unit</b-button>
        </b-col>
      </b-row>
    </b-card-text>
  </b-tab>
</template>
