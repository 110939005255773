<script>

import axios from 'axios';

export default {
  name: 'TabConfigs',
  components: { },
  props: {
    currentTab: { type: Number, required: true },
    tabIndex: { type: Number, required: true },
  },
  data: () => ({
    loaded: false,
    user_id: 0,
    number_of_capacity_levels: 4,
    sorting_algorithms: [],
    pt_leads: [],
    therapist_configs: {
      id: null,
      user_id: null,
      changed_by: null,
      is_clinical_assistant_manager: null, // TODO: What is this ?
      is_ai: null,
      created_at: null,
      updated_at: null,
      deleted_at: null,
      is_lead: null,
      is_test: null,
      is_available_for_predict: null,
      cap_limit: null, // TODO: What is this ?
      ask_a_pt: null, // TODO: What is this ?
      intake_flow: null,
      treatment_flow: null,
      tags: null,
      npi: null,
      lead_id: null,
      capacity_level: null,
      stop_auto_progression: null,
      stop_enrollments: null,
      scoring_algorithm_id: null,
      is_contractor: null,
      is_demo: null,
      is_regular: null,
      is_gutenberg: null,
    },
  }),
  watch: {
    currentTab(value) {
      if (this.tabIndex === value && this.loaded === false) {
        this.fetchData();
        this.fetchScoringAlgorithms();
        this.fetchLeads();
      }
    },
  },
  beforeMount() {
    this.user_id = this.$route.params.id;
  },
  computed: {
    capacityLevelOptions() {
      const levels = Array.from({ length: this.number_of_capacity_levels + 1 }, (_, i) => ({
        value: i,
        text: `Level ${i}`,
      }));

      return [ { value: null, text: 'None' }, ...levels ];
    },
  },
  methods: {
    fetchData() {
      this.loaded = true;
      // TODO change this to the correct endpoint
      return axios.get(`v1/users/${this.user_id}`)
        .then(({ data }) => {
          const therapistConfigs = data.therapistConfigs[0];
          therapistConfigs.scoring_algorithm_id = therapistConfigs.scoring_algorithm_id || null;
          // eslint-disable-next-line prefer-destructuring
          this.therapist_configs = data.therapistConfigs[0];
        })
        .catch(err => {
          this.$noty.error('Could not fetch user');
          console.error('Could not fetch user', err);
        });
    },
    fetchScoringAlgorithms() {
      axios.get('/v1/users/uml/scoring-algorithms', { params: { client: 'back-office' } })
        .then(({ data }) => {
          this.sorting_algorithms = [ { value: null, text: 'Choose a scoring algorithm' } ];
          data.forEach(algorithm => {
            this.sorting_algorithms.push({
              value: algorithm.id,
              text: algorithm.config_slug,
            });
          });
        })
        .catch(err => {
          this.$noty.error('Could not fetch scoring algorithms');
          console.error('Could not fetch scoring algorithms', err);
        });
    },
    fetchLeads() {
      axios.get('v1/professionals/leads', { params: { client: 'back-office' } })
        .then(({ data }) => {
          this.pt_leads = [ { value: null, text: 'Choose a PT lead' } ];
          data.forEach(lead => {
            this.pt_leads.push({
              value: lead.userRefID,
              text: `${lead.firstname} ${lead.lastname} (#${lead.userRefID})`,
            });
          });
        })
        .catch(err => {
          this.$noty.error('Could not fetch PT leads');
          console.error('Could not fetch PT leads', err);
        });
    },
  },
};
</script>

<template>
  <b-tab title="Configs">
    <b-card-text>
      <b-row>
        <b-col cols="4">
          <b-form-group label="Enrollment tags">
            <b-form-checkbox switch :value="1" :unchecked-value="0" v-model="therapist_configs.is_regular">
              Regular</b-form-checkbox>
            <b-form-checkbox switch :value="1" :unchecked-value="0" v-model="therapist_configs.is_demo">
              Demo</b-form-checkbox>
            <b-form-checkbox switch :value="1" :unchecked-value="0" v-model="therapist_configs.is_test">
              Test</b-form-checkbox>
            <b-form-checkbox switch :value="1" :unchecked-value="0" v-model="therapist_configs.is_contractor">
              Contractor</b-form-checkbox>
          </b-form-group>
          <b-form-group label="Professional type">
            <b-form-checkbox switch :value="1" :unchecked-value="0" v-model="therapist_configs.intake_flow">
              Intake flow</b-form-checkbox>
            <b-form-checkbox switch :value="1" :unchecked-value="0" v-model="therapist_configs.treatment_flow">
              Treatment flow</b-form-checkbox>
          </b-form-group>
          <b-form-group label="Other flags">
            <b-form-checkbox switch :value="1" :unchecked-value="0" v-model="therapist_configs.is_lead">
              Lead</b-form-checkbox>
            <b-form-checkbox switch :value="1" :unchecked-value="0" v-model="therapist_configs.is_available_for_predict">
              Available for predict</b-form-checkbox>
            <b-form-checkbox switch :value="1" :unchecked-value="0" v-model="therapist_configs.ask_a_pt">
              Ask a PT</b-form-checkbox>
            <b-form-checkbox switch :value="1" :unchecked-value="0" v-model="therapist_configs.stop_auto_progression">
              Stop Auto Capacity Level Rules
              <b-badge v-b-tooltip.hover class="ml-3" pill variant="primary"
                       title="If you turn ON, the Professional will remain at same Level unless someone changes it manually.
                              As soon you turn OFF, the time to progress will start counting.">
                <feather type="info"/>
              </b-badge>
            </b-form-checkbox>
            <b-form-checkbox switch :value="1" :unchecked-value="0" v-model="therapist_configs.stop_enrollments">
              Stop enrollments
              <b-badge v-b-tooltip.hover class="ml-3" pill variant="primary"
                       title="If you turn ON, the Professional will remain at same Level unless someone changes it manually.
                              As soon you turn OFF, the time to progress will start counting.">
                <feather type="info"/>
              </b-badge>
            </b-form-checkbox>
            <b-form-checkbox switch :value="1" :unchecked-value="0" v-model="therapist_configs.is_gutenberg">
              Gutenberg</b-form-checkbox>
            <b-form-checkbox switch :value="1" :unchecked-value="0" v-model="therapist_configs.is_ai">
              AI</b-form-checkbox>
          </b-form-group>
        </b-col>
        <b-col cols="8">
          <b-form-group label="NPI">
            <b-form-input v-model.trim="therapist_configs.npi" type="text" min="0" max="10" />
          </b-form-group>
          <b-form-group label="Assigned PT Lead">
            <b-form-select v-model="therapist_configs.lead_id" :options="pt_leads"/>
          </b-form-group>
          <b-form-group label="UML - Scoring Algorithm Version">
            <b-form-select v-model="therapist_configs.scoring_algorithm_id" :options="sorting_algorithms"/>
          </b-form-group>
          <b-form-group label="Capacity Level">
            <b-form-select v-model="therapist_configs.capacity_level" :options="capacityLevelOptions"/>
          </b-form-group>
        </b-col>
      </b-row>
      <hr />
      <b-row>
        <b-col cols="12">
          <b-button variant="primary" class="float-right">Save configs</b-button>
        </b-col>
      </b-row>
    </b-card-text>
  </b-tab>
</template>
