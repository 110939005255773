<script>
import axios from 'axios';
import InputFeedbackMessage from '@/components/InputFeedbackMessage.vue';
import { VIRTUAL_PT_PROGRAM_TYPE } from '@/scripts/constants';

export default {
  name: 'TabUser',
  components: { InputFeedbackMessage },
  props: {
    currentTab: { type: Number, required: true },
    tabIndex: { type: Number, required: true },
  },
  data: () => ({
    isTwilioPhoneNumberValid: true,
    loaded: false,
    user_id: 0,
    measurement_system_options: [
      { value: 'metric', text: 'Metric' },
      { value: 'imperial', text: 'Imperial' },
    ],
    gender_options: [
      { value: 'Male', text: 'Male' },
      { value: 'Female', text: 'Female' },
      { value: '', text: 'N/A' },
    ],
    user: {
      id: null,
      acl_uuid: null,
      card_id: null,
      card_uuid: null,
      pin_code: null,
      username: null,
      password: null,
      email: null,
      email_hash: null,
      direct_message_address: null,
      firstname: null,
      lastname: null,
      prefix: null,
      telephone: null,
      telephone_hash: null,
      gender: null,
      picture: null,
      has_photo: null,
      photo_driver: null,
      language: null,
      languages: null, // TODO remove from DB. The one used is in the therapist_information
      measurement_system: null,
      remember_token: null,
      is_clinical_assistant_manager: null,
      data_level_access: null,
      program_type: null,
      has_provider_enabled: null,
      created_at: null,
      updated_at: null,
      deleted_at: null,
      telephone_can_receive_sms: null,
      external_phone_number: null,
    },
  }),
  watch: {
    currentTab(value) {
      if (this.tabIndex === value && this.loaded === false) {
        this.fetchData();
      }
    },
  },
  beforeMount() {
    this.user_id = this.$route.params.id;
  },
  computed: {
    showProviderStrategyToggle() {
      return this.user.program_type === VIRTUAL_PT_PROGRAM_TYPE;
    },
  },
  methods: {
    fetchData() {
      return axios.get(`v1/users/${this.user_id}`)
        .then(({ data }) => {
          this.user = data.user;
        })
        .catch(err => {
          this.$noty.error('Could not fetch user');
          console.error('Could not fetch user', err);
        });
    },
    isValidNPI(npi) {
      if (!npi) {
        return true; // allow empty
      }
      return npi.match(/^\d{10}$/);
    },
    saveDataConfirmation() {
      return this.$bvModal.msgBoxConfirm('Save user ?')
        .then(result => {
          if (result) {
            this.saveData();
          }
        });
    },
    saveData() {
      if (!this.isValidNPI(this.user.npi)) {
        this.$noty.error('National Provider Identifier (NPI) must be a 10-digit number');
        return;
      }

      // TODO update to the new endpoint. This one wants the user and professional data
      axios.put(`v1/users/${this.user_id}`, this.user)
        .then(({ data }) => {
          if (data.error) {
            this.$noty.error(data.message);
            return;
          }
          this.$noty.success('User data saved successfully');
        })
        .catch(err => {
          this.$noty.error('Could not save user data');
          console.error('Could not save user', err);
        });
    },
  },
};
</script>

<template>
  <b-tab title="User">
    <b-card-text>
      <b-card>
        <b-row>
          <b-col cols="8">
            <small><b>Access level:</b> {{ user.data_level_access }}</small>
          </b-col>
          <b-col cols="4">
            <small class="d-block"><b>Created:</b> {{ user.created_at }} UTC</small>
            <small class="d-block"><b>Last update:</b> {{ user.updated_at }} UTC</small>
          </b-col>
        </b-row>
      </b-card>
      <b-row class="mt-3">
        <b-col cols="6">
          <b-form-group label="Username">
            <b-form-input v-model="user.username" disabled/>
          </b-form-group>
          <b-form-group label="ACL UUID">
            <b-form-input v-model="user.acl_uuid" disabled/>
          </b-form-group>
          <b-form-group label="Email">
            <b-form-input v-model.trim="user.email" type="email"/>
          </b-form-group>
          <b-form-group label="First name">
            <b-form-input v-model.trim="user.firstname"/>
          </b-form-group>
          <b-form-group label="Last name">
            <b-form-input v-model.trim="user.lastname"/>
          </b-form-group>
          <b-form-group label="Gender">
            <b-form-select v-model="user.gender" :options="gender_options"/>
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group label="Phone">
            <b-form-input id="phone" v-model.trim="user.telephone" />
          </b-form-group>
          <b-form-group label="Twilio Phone Number">
            <b-form-input id="externalPhoneNumber" v-model.trim="user.external_phone_number" type="tel" />
          </b-form-group>
          <InputFeedbackMessage v-if="!isTwilioPhoneNumberValid" class="text-danger"
                                data-testid="userPhoneInputMessageSMSComplaint" icon-name="alert-triangle"
                                message="This Phone Number is not eligible for SMS" />
          <b-form-group label="Measurement system">
            <b-form-select v-model="user.measurement_system" :options="measurement_system_options" required/>
          </b-form-group>
          <b-form-group v-if="showProviderStrategyToggle" label="Provider Strategy">
            <b-form-checkbox v-model="user.has_provider_enabled" name="has_provider_enabled" switch>
              Has Provider Enabled</b-form-checkbox>
          </b-form-group>
        </b-col>
      </b-row>
      <hr />
      <b-row>
        <b-col cols="12">
          <b-button variant="primary" class="float-right" @click="saveDataConfirmation">Save user</b-button>
        </b-col>
      </b-row>
    </b-card-text>
  </b-tab>
</template>
