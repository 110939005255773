<script>

import TabUser from '@/views/Users/Professionals/Edit/Tabs/User.vue';
import TabConfigs from '@/views/Users/Professionals/Edit/Tabs/Configs.vue';
import TabUnits from '@/views/Users/Professionals/Edit/Tabs/Units.vue';
import TabInformation from '@/views/Users/Professionals/Edit/Tabs/Information.vue';
import TabCredentials from '@/views/Users/Professionals/Edit/Tabs/Credentials.vue';
import TabLicenses from '@/views/Users/Professionals/Edit/Tabs/Licenses.vue';

export default {
  name: 'ProfessionalEdit',
  components: {
    TabLicenses,
    TabCredentials,
    TabInformation,
    TabUnits,
    TabUser,
    TabConfigs,
  },
  data: () => ({
    currentTab: 0,
    user_id: 0,
  }),
  computed: {},
  beforeMount() {
    this.user_id = this.$route.params.id;
  },
  methods: {},
};
</script>

<template>
  <b-container class="mt-4" fluid>
    <b-row>
      <b-col cols="12">
        <h5><b-badge>Beta</b-badge> Edit Professional {{ user_id }}</h5>
      </b-col>
    </b-row>
    <b-card no-body>
      <b-tabs card pills vertical v-model="currentTab">
        <TabInformation :current-tab="currentTab" :tab-index="0"/>
        <TabUser :current-tab="currentTab" :tab-index="1"/>
        <TabUnits :current-tab="currentTab" :tab-index="2"/>
        <TabConfigs :current-tab="currentTab" :tab-index="3"/>
        <TabCredentials :current-tab="currentTab" :tab-index="4"/>
        <TabLicenses :current-tab="currentTab" :tab-index="5"/>
      </b-tabs>
    </b-card>
  </b-container>
</template>
