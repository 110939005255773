<script>

import axios from 'axios';

export default {
  name: 'TabInformation',
  components: {},
  props: {
    currentTab: { type: Number, required: true },
    tabIndex: { type: Number, required: true },
  },
  data: () => ({
    loaded: false,
    user_id: 0,
    available_countries: [],
    user: {},
    therapist_information: {
      id: null,
      user_id: null,
      provides_remote_therapy: 0,
      has_presentation_picture: 0,
      profile_description: null,
      video_call_url: null,
      country: null,
      states: null,
      preferred_timezone: null,
      about: null,
      specialities: null,
      special_interests: null,
      hobbies_and_loves: null,
      located_currently_state: null,
      located_originally_state: null,
      education_and_certifications: null,
      languages: null,
      created_at: null,
      updated_at: null,
      deleted_at: null,
      move_configuration: null,
      presentation_video: null,
      voicemail_recording: null,
      presentation_picture_url: null,
    },
  }),
  watch: {
    currentTab(value) {
      if (this.tabIndex === value && this.loaded === false) {
        this.fetchData();
      }
    },
  },
  beforeMount() {
    this.user_id = this.$route.params.id;
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.loaded = true;
      // TODO change this to the correct endpoint
      return axios.get(`v1/users/${this.user_id}`)
        .then(({ data }) => {
          this.user = data.user;
          // eslint-disable-next-line prefer-destructuring
          this.therapist_information = data.therapistInformation[0];
        })
        .catch(err => {
          this.$noty.error('Could not fetch user');
          console.error('Could not fetch user', err);
        });
    },
    isMoveConfigurationValid() {
      try {
        JSON.parse(this.user?.move_configuration || '{}');
        return true;
      } catch (error) {
        return false;
      }
    },
  },
};
</script>

<template>
  <b-tab title="Information">
    <b-card-text>
      <b-row>
        <b-col cols="4">
          <img alt="presentation_picture_url" height="200px" class="mb-3"
               :src="therapist_information.presentation_picture_url"/>
          <b-form-group label="States">
            <ul>
              <li v-for="(item, index) in therapist_information.states" :key="index">{{ item }}</li>
            </ul>
          </b-form-group>
          <b-form-group label="Education and certifications">
            <ul>
              <li v-for="(item, index) in therapist_information.education_and_certifications" :key="index">{{ item }}
              </li>
            </ul>
          </b-form-group>
          <b-form-group label="Languages">
            <ul>
              <li v-for="(item, index) in therapist_information.languages" :key="index">{{ item }}</li>
            </ul>
          </b-form-group>
          <b-form-group label="Special interests">
            <ul>
              <li v-for="(item, index) in therapist_information.special_interests" :key="index">{{ item }}</li>
            </ul>
          </b-form-group>
          <b-form-group label="Hobbies and loves">
            <ul>
              <li v-for="(item, index) in therapist_information.hobbies_and_loves" :key="index">{{ item }}</li>
            </ul>
          </b-form-group>
          <b-form-group label="Specialities">
            <ul>
              <li v-for="(item, index) in therapist_information.specialities" :key="index">{{ item }}</li>
            </ul>
          </b-form-group>
        </b-col>
        <b-col cols="8">
          <b-form-group>
            <b-form-checkbox switch :value="1" :unchecked-value="0" disabled
                             v-model="therapist_information.provides_remote_therapy">
              Provides remote therapy
            </b-form-checkbox>
          </b-form-group>
          <b-form-group label="Video call URL">
            <b-form-input v-model.trim="therapist_information.video_call_url" disabled />
          </b-form-group>
          <b-form-group label="Country">
            <b-form-input v-model="therapist_information.country" disabled />
          </b-form-group>
          <b-form-group label="Preferred timezone">
            <b-form-input :value="therapist_information.preferred_timezone" disabled />
          </b-form-group>
          <b-form-group label="Located currently state">
            <b-form-input :value="therapist_information.located_currently_state" disabled />
          </b-form-group>
          <b-form-group label="Located originally state">
            <b-form-input :value="therapist_information.located_originally_state" disabled />
          </b-form-group>
          <b-form-group label="Presentation video">
            <b-form-input :value="therapist_information.presentation_video" disabled />
          </b-form-group>
          <b-form-group label="Voicemail recording">
            <b-form-input :value="therapist_information.voicemail_recording" disabled />
          </b-form-group>
          <b-form-group label="Profile description">
            <b-form-textarea v-model.trim="therapist_information.profile_description" disabled />
          </b-form-group>
          <b-form-group label="About">
            <b-form-textarea v-model.trim="therapist_information.about" disabled />
          </b-form-group>
          <b-form-group :state="isMoveConfigurationValid()" v-if="user.unit === 'move'"
                        invalid-feedback="Invalid json. Unable to parse." label="Move configurations">
            <b-form-textarea :value="therapist_information.move_configuration" disabled />
          </b-form-group>
        </b-col>
      </b-row>
    </b-card-text>
  </b-tab>
</template>
